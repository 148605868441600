import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Badge } from '@wix/ambassador-badges-v3-badge/types';
import { withResolvedError } from '@wix/members-area-commons-ts';

import { refetchMemberBadges } from '../../../server/profile.api';
import type { ThunkApiConfig } from '../../../types';

interface FetchAssignedBadgesResponse {
  badges: Badge[];
  assignedBadges: Badge[];
}

export const fetchAssignedBadges = createAsyncThunk<
  FetchAssignedBadgesResponse,
  string[],
  ThunkApiConfig
>(
  'badges/fetchAssignedBadges',
  async (assignedBadgeIds, { extra: { flowAPI } }) => {
    const { httpClient, errorHandler } = flowAPI;

    const requestFn = () =>
      httpClient.request(refetchMemberBadges({ assignedBadgeIds }));

    const { data } = await withResolvedError(requestFn, errorHandler, {
      errorCodesMap: {},
    });

    return data;
  },
);
