import { createAsyncThunk } from '@reduxjs/toolkit';
import { withResolvedError } from '@wix/members-area-commons-ts';

import settingsParams, {
  defaultPostListPageSize,
} from '../../../components/Profile/settingsParams';
import { loadMoreBlogPosts } from '../../../server/profile.api';
import { toNumberOrDefault } from '../../../services/number-converter';
import type { BlogPost, ThunkApiConfig } from '../../../types';

interface FetchPostsResponse {
  pagingOffset: number;
  fetchedPostsCount: number;
  blogPosts: BlogPost[];
}

export const fetchPosts = createAsyncThunk<
  FetchPostsResponse,
  void,
  ThunkApiConfig
>('blog/fetchPosts', async (_, { getState, extra: { flowAPI } }) => {
  const { member, blogPosts } = getState();
  const { httpClient, environment, errorHandler, settings } = flowAPI;

  const postsPerPage = toNumberOrDefault(
    settings.get(settingsParams.postListPageSize),
    defaultPostListPageSize,
  );
  const pagingOffset = blogPosts.paging.offset + postsPerPage;

  const requestFn = () =>
    httpClient.request(
      loadMoreBlogPosts({
        viewedMemberId: member.id!,
        postsPerPage,
        pagingOffset,
        language: environment.language,
        isMultilingualEnabled: environment.multilingual?.isEnabled ?? false,
      }),
    );

  const { data } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return {
    pagingOffset,
    fetchedPostsCount: data.posts.length,
    blogPosts: data.posts,
  };
});
