import { createSlice } from '@reduxjs/toolkit';

import { blockedMemberId } from '../../../constants';
import type { Member } from '../../../types';
import { initialStateRefetch } from '../../thunk';
import { fetchAssignedBadges } from '../badges/thunk';
import { fetchPosts } from '../blog-posts/thunk';
import { publishMyAbout } from './thunk';

export type State = Member;

const initialState = null! as State;

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    blockMember: (state) => {
      state.id = blockedMemberId;
      state.createdDate = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssignedBadges.fulfilled, (state, action) => {
      state.badges = action.payload.assignedBadges;
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.blogPosts.push(...action.payload.blogPosts);
    });
    builder.addCase(publishMyAbout.fulfilled, (state, { payload }) => {
      state.about = payload;
    });
    builder.addCase(initialStateRefetch.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload.member,
      };
    });
  },
});

const { actions } = memberSlice;

export const { blockMember } = actions;
