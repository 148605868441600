import { blogContributorRoles } from '../../../../constants';
import type { Role } from '../../../../types';

export const getIsBlogContributor = (
  isBlogInstalled: boolean,
  memberRoles: Role[],
) => {
  return (
    isBlogInstalled &&
    memberRoles.some((role) => blogContributorRoles.includes(role))
  );
};
