import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ServerErrorCode, Toast } from '../../../types';
import { publishMyAbout } from '../member/thunk';

type State = {
  isPublicProfilePreview: boolean;
  toast: {
    isVisible: boolean;
    type: Toast;
  };
  publishButton: {
    isDisabled: boolean;
  };
};

const initialState: State = {
  isPublicProfilePreview: false,
  toast: {
    isVisible: false,
    type: Toast.None,
  },
  publishButton: {
    isDisabled: false,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsPublicProfilePreview(state, { payload }: PayloadAction<boolean>) {
      state.isPublicProfilePreview = payload;
    },
    hideToast(state) {
      state.toast.type = Toast.None;
      state.toast.isVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(publishMyAbout.fulfilled, (state) => {
        state.toast.type = Toast.AboutPublish;
        state.toast.isVisible = true;
        state.publishButton.isDisabled = false;
      })
      .addCase(publishMyAbout.pending, (state) => {
        state.publishButton.isDisabled = true;
      })
      .addCase(publishMyAbout.rejected, (state, { payload }) => {
        switch (payload as ServerErrorCode) {
          case ServerErrorCode.TooManyLinksInAbout:
            state.toast.type = Toast.AboutPublishErrorTooManyLinks;
            break;
          case ServerErrorCode.TextTooLongInAbout:
            state.toast.type = Toast.AboutPublishErrorTextTooLong;
            break;
          default:
            state.toast.type = Toast.AboutPublishErrorUnknown;
            break;
        }
        state.toast.isVisible = true;
        state.publishButton.isDisabled = false;
      });
  },
});

export const { setIsPublicProfilePreview } = uiSlice.actions;
