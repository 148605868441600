import { createSlice } from '@reduxjs/toolkit';

import type { Site } from '../../../types';
import { initialStateRefetch } from '../../thunk';

export type State = Site;

const initialState: State = {
  sameSessionMember: false,
  isForumInstalled: false,
  isBlogInstalled: false,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialStateRefetch.fulfilled, (state, action) => {
      state.sameSessionMember = action.payload.site.sameSessionMember;
    });
  },
});
