import { bindActionCreators, configureStore } from '@reduxjs/toolkit';

import type { Extra } from '../types';
import type { RootState } from './root-reducer';
import { rootReducer } from './root-reducer';
import { blogPostsThunk, memberThunk, siteThunk, uiSlice } from './slices';

export const createStore = (
  extraArgument: Extra,
  initialState: Partial<RootState>,
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          isSerializable: () => true,
        },
        thunk: { extraArgument },
      }),
  });

export type Store = ReturnType<typeof createStore>;

export const getActionHandlers = (store: Store) => ({
  memberHandlers: bindActionCreators(memberThunk, store.dispatch),
  blogPostsHandlers: bindActionCreators(blogPostsThunk, store.dispatch),
  siteHandlers: bindActionCreators(siteThunk, store.dispatch),
  uiHandlers: bindActionCreators(uiSlice.actions, store.dispatch),
});
