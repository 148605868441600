import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { PostImageFill, PostImageRatio } from '../../types';

export type ISettingsParams = {
  aboutSectionTitle: SettingsParamType.Text;
  aboutDescriptionPlaceholder: SettingsParamType.Text;
  badgesSectionTitle: SettingsParamType.Text;
  overviewSectionTitle: SettingsParamType.Text;
  blogPostsTitle: SettingsParamType.Text;
  blogPostsEmptyStateTitle: SettingsParamType.Text;
  blogPostsEmptyStateDescription: SettingsParamType.Text;
  headerSectionTitle: SettingsParamType.Text;
  headerSectionSubtitle: SettingsParamType.Text;
  headerSectionButtonText: SettingsParamType.Text;
  showAboutSection: SettingsParamType.Boolean;
  showBadgesSection: SettingsParamType.Boolean;
  showOverviewSection: SettingsParamType.Boolean;
  showBlogPostsSection: SettingsParamType.Boolean;
  postListPageSize: SettingsParamType.Number;
  showBlogPostsCounter: SettingsParamType.Boolean;
  showPostCover: SettingsParamType.Boolean;
  showPostPublishDate: SettingsParamType.Boolean;
  showPostReadingTime: SettingsParamType.Boolean;
  showPostExcerpt: SettingsParamType.Boolean;
  showPostViewsCounter: SettingsParamType.Boolean;
  showPostCommentsCounter: SettingsParamType.Boolean;
  showPostLikesCounter: SettingsParamType.Boolean;
  // TODO: Need to remove usage of this settings param in React code
  // Reference: https://github.com/wix-private/members-area/blob/274b8ae2868eb022c30d52d90741a73d8dc76c09/packages/members-about-ooi/src/components/Profile/Widget/BlogPosts/PostList/Post/Cover/hooks.ts
  postImageFill: SettingsParamType.String;
  postImageRatio: SettingsParamType.Number;
  // TODO: Need to remove usage of this settings param in the Server code
  // Reference: https://github.com/wix-private/members-area/blob/274b8ae2868eb022c30d52d90741a73d8dc76c09/packages/members-about-ooi/src/types/server.ts
  showCommentsStats: SettingsParamType.Boolean;
  showLikesReceivedStats: SettingsParamType.Boolean;
  showTopCommentsStats: SettingsParamType.Boolean;
  //
  showJoinDate: SettingsParamType.Boolean;
  emptyStateTitle: SettingsParamType.Text;
  emptyStateText: SettingsParamType.Text;
  isMobileSectionSettingsEnabled: SettingsParamType.Boolean;
};

export const defaultPostListPageSize = 3;

export default createSettingsParams<ISettingsParams>({
  aboutSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about-page.about'),
  },
  aboutDescriptionPlaceholder: {
    key: 'placeholder',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('settings.texts-tab.placeholder-default-text');
    },
  },
  badgesSectionTitle: {
    key: 'badgesTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about-page.badges'),
  },
  overviewSectionTitle: {
    key: 'overviewTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about.widget.overview'),
  },
  blogPostsTitle: {
    key: 'postListTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('recent-posts.posts'),
  },
  blogPostsEmptyStateTitle: {
    key: 'postListEmptyStateTitle',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-posts.title'),
  },
  blogPostsEmptyStateDescription: {
    key: 'postListEmptyStateLinkText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-posts.write-your'),
  },
  headerSectionTitle: {
    key: 'headerTitleText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about.widget.profile'),
  },
  headerSectionSubtitle: {
    key: 'headerSubtitleText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about-page.joined-date-text-only'),
  },
  headerSectionButtonText: {
    key: 'headerButtonText',
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('about.widget.edit-profile'),
  },
  showAboutSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBadgesSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showOverviewSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBlogPostsSection: {
    key: 'showPostListSection',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showBlogPostsCounter: {
    key: 'showPostListCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  showJoinDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCommentsStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showLikesReceivedStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTopCommentsStats: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  postListPageSize: {
    type: SettingsParamType.Number,
    getDefaultValue: () => defaultPostListPageSize,
  },
  postImageFill: {
    key: 'postListLayoutResizing',
    type: SettingsParamType.String,
    getDefaultValue: () => PostImageFill.Fill,
  },
  postImageRatio: {
    key: 'postListImageRatio',
    type: SettingsParamType.Number,
    getDefaultValue: () => PostImageRatio.OneToOne,
  },
  showPostCover: {
    key: 'postListShowCoverImage',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostPublishDate: {
    key: 'postListShowPublishDate',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostReadingTime: {
    key: 'postListShowReadingTime',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostExcerpt: {
    key: 'postListShowDescription',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostViewsCounter: {
    key: 'postListShowViewsCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostCommentsCounter: {
    key: 'postListShowCommentsCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showPostLikesCounter: {
    key: 'postListShowLikesCounter',
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  emptyStateTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-about.title'),
  },
  emptyStateText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('no-about.content'),
  },
  isMobileSectionSettingsEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
