export interface ModalProps {
  isOpen: boolean;
  onClose(): void;
}

export enum Toast {
  None,
  AboutPublish,
  AboutPublishErrorTooManyLinks,
  AboutPublishErrorTextTooLong,
  AboutPublishErrorUnknown,
}
