import { dsl } from '@wix/yoshi-serverless/wrap';

export const getInitialData = dsl({
          functionName: 'getInitialData',
          fileName: 'server/profile.api',
        });

export const loadMoreBlogPosts = dsl({
          functionName: 'loadMoreBlogPosts',
          fileName: 'server/profile.api',
        });

export const updateAbout = dsl({
          functionName: 'updateAbout',
          fileName: 'server/profile.api',
        });

export const fetchAllBadges = dsl({
          functionName: 'fetchAllBadges',
          fileName: 'server/profile.api',
        });

export const refetchMemberBadges = dsl({
          functionName: 'refetchMemberBadges',
          fileName: 'server/profile.api',
        });