import { Role } from './types';

export const profileCardAppDefId = '14ce28f7-7eb0-3745-22f8-074b0e2401fb';
export const myAccountAppDefId = '14cffd81-5215-0a7f-22f8-074b0e2401fb';
export const myAccountPageId = 'member_info';

export const MY_ACCOUNT_PAGE_WIDGET_ID = '14dd1af6-3e02-63db-0ef2-72fbc7cc3136';

export const blogContributorRoles = [
  Role.Admin,
  Role.BlogEditor,
  Role.BlogWriter,
];

export const blockedMemberId = '-1';
