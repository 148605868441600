export enum SettingsTab {
  Main = 'main',
  Layout = 'layout',
  Design = 'design',
  Sections = 'sections',
  Support = 'support',
}

export enum SettingsSection {
  Header = 'header',
  About = 'about',
  PostList = 'post-list',
  TextStyle = 'text-style',
}

export enum SettingsSectionState {
  Default = 'default',
  Empty = 'empty',
}

export interface SettingsEvent {
  tabChanged: SettingsTab;
  sectionChanged: SettingsSection;
  sectionStateChanged: SettingsSectionState;
}
